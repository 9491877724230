import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorKatherine as author } from 'data/authors';
import { useSourceParams } from 'hooks/useSourceParams';
import img from 'img/blog/covers/cover-old-content.png';

const Content = () => {
  const cta = useSourceParams('banner-cta');
  return (
    <Post>
      <p>
        In an increasingly fast-paced digital world, we are constantly bombarded with new content.
        24-hour news cycles have created a platform for a constant stream of content and continuous
        access to social channels through mobile devices means that content is always in our pocket.
        For brands, it can become a full-time job trying to keep up with the endless consumption of
        content that customers seemingly demand. Trying to figure out what to post on social
        channels to grab customers’ attention is a task of Herculean proportions.
      </p>

      <p>
        Whilst it’s true that fresh content is an essential element of digital marketing and
        customer engagement, many businesses are too caught up in this whirlwind of content to pause
        and take note. With a constant emphasis on new content, quality sometimes gets lost in the
        drive to create, and analytics - the question of how your content is being received - often
        goes out the window altogether.
      </p>

      <p>
        This means that businesses are missing valuable opportunities to learn about their audience
        and the content they appreciate. Sometimes, a high-performing piece of content will engage
        your audience better the second time around, and your most engaging content might be
        something your audience will appreciate time and again.{' '}
      </p>
      <h2>Engage And Re-Engage: Begin By Discovering Your High Performing Content</h2>
      <p>
        Working hard or working smart? You could exhaust your creative juices coming up with fresh
        content every day - or you could rediscover your top performing content from the past. Let’s
        take a look at how you do that.
      </p>
      <h3>1. Utilize Analytics</h3>
      <p>
        Taking advantage of the analytic tools that are out there can revolutionize your content.
        Sometimes engaging with social media can feel like shouting into the void - and it’s so
        frustrating when you post some hard-earned content, only for it to seemingly slip under the
        radar. Analytics provides you with the context for how your audience is engaging with your
        post - remember, knowledge is power.
      </p>

      <p>
        Google Analytics is one of the best tools out there for exploring the performance of your
        content. You’ll be able to see, out of all your content, what was the most viewed and the
        most shared. Google’s Analytic tools are far from the only option out there too, so if you
        want more specificity in how you approach your data, explore some other options.
      </p>
      <h3>2. Social Statistics</h3>
      <p>
        Most social media platforms have their own analytics that you can access somewhere in the
        backdrop of your account. Instagram, for example, offers businesses the ability to see how
        many shares their post has had, as well as how many profile views came as a direct result
        from your post.
      </p>

      <p>
        “Tracking your most-shared content is an essential way of identifying your top-performing
        posts,” says Jennifer Erwin, a content expert at{' '}
        <a href="https://boomessays.com/" rel="noopener noreferrer nofollow" target="_blank">
          Boomessays
        </a>
        . “When posts get shared that gives your engagement existential growth - the holy grail of
        content marketing.” Utilize social platforms’ own internal analytics to identify which posts
        are resonating with your audience and getting the best reach - this will give you an archive
        of great content to draw upon in the future.
      </p>
      <h2>Bringing Your Content Back To Life</h2>
      <p>
        {' '}
        Having identified your top-performing content of yore, you have the opportunity to re-engage
        your audience with it and expand the reach of your social media. Sometimes simply reposting
        your content with a simple introduction such as “One from the archives…” might be enough to
        merit a share. But to get the most life out of your content you can reanimate it for new
        audiences. Let’s see the best ways to bring your content back to life. In addition, you can
        always hire a freelancer to create custom animations, graphic designs or extra copy to give
        your content the boost it needs, provided it’s{' '}
        <a
          href="https://www.twine.fm/blog/hire-freelancer-cost/"
          rel="noopener noreferrer"
          target="_blank"
        >
          within your budget
        </a>
        .
      </p>
      <h3>1. Repurposing Content Into New Forms</h3>
      <p>
        There are so many ways you can{' '}
        <a
          href="https://clipchamp.com/en/blog/benefits-online-video/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          share content from text to video
        </a>
        ,{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://visme.co/blog/how-to-make-an-infographic/"
        >
          images to infographics
        </a>
        . And different platforms encourage information to be shared in different ways. For example,
        a text post that you have identified as performing well on Facebook could be repurposed into
        a series of slides for an information Instagram post.
      </p>
      <p>
        Get creative with how you repurpose your best-performing content and you’ll give it a second
        lease of life. Even platforms that predominantly encourage sharing in one form - such as
        Instagram and images - are ripe for subverting with alternative content. See how
        microblogging on Instagram has taken off. Your content can take so many forms!
      </p>
      <h3>2. Leverage The Reach Of Influencers And Bloggers</h3>
      <p>
        Influencers are a big deal across multiple social platforms and they’ve curated a unique,
        authentic, and highly engaged audience that’s just waiting for your content. You can
        approach influencers by direct message to see if they’re interested in working for you or,
        take a more organic approach by engaging with them on their own accounts first.
      </p>

      <p>
        By getting the attention of those with big followings you increase the chance of getting
        them to share your content with their audience. If they like your posts, this can happen
        entirely organically. This is a great strategy for bloggers too who have their own curated
        audience.
      </p>
      <h3>3. Discover New Platforms For Your Content</h3>
      <p>
        It feels like new social platforms are created every day so it’s likely there’ll be one or
        two out there that you haven’t gotten onto yet. Even if the majority of these platforms
        never compete with the likes of Facebook or Instagram you never know which will be the next
        big thing.
      </p>

      <p>
        What’s more, each platform will have its own demographic as an audience. From Tik Tok to
        LinkedIn, there’s always a new audience for your content.
      </p>
      <h3>4. Personalize Content For Key Viewers</h3>
      <p>
        Whatever your industry, there will be some major discrepancies in your target market. Take
        your old content and tweak it in a way that appeals to specific subsets of the demographics
        you’re trying to reach. Personalize content to create tailored posts that maximize
        engagement.
      </p>
      <h2>Measuring Your Content’s Engagement</h2>
      <p>
        Lastly, it’s essential to measure the engagement your content gets. This will guide your
        strategy in the future.
      </p>
      <h3>1. Comments</h3>
      <p>
        Whilst it’s great if people are double-tapping to like the content you’ve posted on
        Instagram or if your Facebook posts are bringing the thumbs, but comments take precedence
        over likes in the engagement hierarchy. If people have been interested enough in your post
        to leave a comment then you know they’re engaged - scan your blog posts and social media to
        see what people are commenting on, and where.
      </p>
      <h3>2. Social Shares</h3>
      <p>
        Chances are, your most engaging content is being widely shared. A shared post reaches the
        audience of whoever has shared it, so it’s not only your followers who are seeing your
        content but an ever-increasing audience. Incentivizing your followers to share your posts
        through offers and giveaways can take your engagement to another level.
      </p>
      <h3>3. Amount Of Pageviews</h3>
      <p>
        For blog posts, pageviews are an important metric in how successful your content has been.
        For a good sense of how your content is performing you should know a rough average of how
        many views your blogs get - you can then gauge if a specific content is exceeding
        expectations. And it’s equally important to know where these views are coming from.
      </p>
      <h3>4. Organic Traffic</h3>
      <p>
        “Tracking your traffic and identifying how much of it is organic is important to
        understanding the performance of your post,” says Elizabeth Jenkins, a copywriter at{' '}
        <a href="https://stateofwriting.com/" rel="noopener noreferrer nofollow" target="_blank">
          State Of Writing
        </a>
        . Organic traffic, as opposed to paid traffic resulting from ads, will indicate that your
        content is being shared far and wide.
      </p>
      <h3>5. Realtime Tools</h3>
      <p>
        LiveSession is a fantastic tool that lets you record and replay the way your customers have
        interacted with your content on social channels. LiveSession gives you the ability to see
        through your customers eyes and can provide great insight into how customers engage with
        your content. For example, you can assess where customers&apos; attention is being directed
        - if you have highly informative content with a mixture of infographics and text, this
        analytic tool can make it clear if people are engaging with the whole post or just certain
        elements. You’ll be able to recycle popular graphics in your content cycle once you have
        this data.
      </p>
      <a href={cta.link} target="_blank" rel="noopener noreferrer" className="action-banner">
        Record and replay sessions on your website now. Try LiveSession for free.
      </a>
      <h3>6. Engagement With Target Accounts</h3>
      <p>
        Does the engagement on your content have a strong overlap with the accounts you’re most
        interested in reaching? If you’ve identified niche target markets you can gauge the
        performance of your content both in terms of absolute numbers as well as in how these
        demographics are engaging with your content. Ideally, everyone who likes, comments and
        shares your posts is a potential customer - once you’ve maximized engagement with an
        audience that is tailored to the demands of your products, your business is ready to grow.
      </p>
      <h2>Wrapping Up</h2>
      <p>
        The drive for fresh content can sometimes be fallacious - old content can be brought back to
        life with these strategies. By finding new platforms, new audiences and by tweaking old
        content into new forms you can find next level engagement with the content you’re already
        sitting on. Don’t forget the value of analytics to discover your top content, as well as to
        continually measure your content’s performance and adjust your strategies accordingly. Don’t
        exhaust yourself with constant new content - recycle your content for a green strategy.
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'How To Bring Old Content Back To Life And Discover New Levels Of Engagement',
  url: '/blog/how-to-bring-old-content-back-to-life-and-discover-new-levels-of-engagement/',
  description: `Sometimes, a high-performing piece of content will engage your audience better the second time around, and your most engaging content might be something your audience will appreciate time and again.`,
  author,
  img,
  imgSocial: img,
  date: '2020-11-16',
  category: '',
  group: 'Article',
  timeToRead: 7,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
